<template>
  <div class="account">
    <h1 class="h4">{{ $t(`${tPath}.title`) }}</h1>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.user.title`) }}</h2>
    <entity-edit-form
      :isBusy="is.loading"
      :entityKey="entityKey"
      :entityId="entity.id || ''"
      :translationsPath="`${tPath}.user.editform`"

      @form:update="entity => updateEntity(entityKey, entity)"
    />

    <template v-if="subEntityAllowed">
      <div v-for="(subEntityType, tKey) in subEntityTypes" :key="tKey" :class="['subentity-type', `type-${tKey}`]">
        <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.user.${tKey}.title`) }}</h2>
        <entity-edit-form
          v-for="subEntity in subEntityType"
          :key="subEntity.id"
          :isBusy="subEntityTypesIs[tKey].loading"
          :entityKey="subEntityTypeKeyMap[tKey]"
          :entityId="subEntity.id"
          inline
          allowDeletion
          class="subentity"
          :translationsPath="`${tPath}.user.${tKey}.editform`"

          @form:update="subEntity => updateEntity(subEntityTypeKeyMap[tKey], subEntity)"
          @form:delete="subEntity => deleteEntity(subEntityTypeKeyMap[tKey], subEntity)"
        />
        <div v-if="subEntityTypeAddFormStates[tKey]" class="subentity-addform">
          <h3 class="h6">{{ $t(`${tPath}.user.${tKey}.addform.title`) }}</h3>
          <entity-form
            :id="`SubEntityAddForm_${tKey}`"
            :isBusy="subEntityTypesIs[tKey].loading"
            :entityKey="subEntityTypeKeyMap[tKey]"
            :controlDefinition="subEntityTypesDefinitions[tKey]"
            showButtons
            showAbortButton

            @form:abort="toggleSubEntityAddForm(tKey)"
            @form:submit="$formdata => createEntity(subEntityTypeKeyMap[tKey], $formdata) & toggleSubEntityAddForm(tKey)"
          >
            <template #abort><lazy-icon icon="remove"/> {{ $t(`${tPath}.user.${tKey}.addform.action.abort`) }}</template>
            <template #submit><lazy-icon icon="save"/> {{ $t(`${tPath}.user.${tKey}.addform.action.save`) }}</template>
          </entity-form>
        </div>

        <div v-else class="subentity-actions">
          <a href="#" @click.prevent="toggleSubEntityAddForm(tKey)"><lazy-icon icon="add"/> {{ $t(`${tPath}.user.${tKey}.addform.toggle`) }}</a>
        </div>
      </div>
    </template>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.user.password.title`) }}</h2>
    <slot name="passwordupdate"/>
  </div>
</template>

<script>
import { singularize } from '@/assets/js/helper/string'

import EntityForm from '@/components/private/forms/EntityForm'
import EntityEditForm from '@/components/private/forms/EntityEditForm'

const ENTITY_KEY = 'users'
const SUBENTITY_TYPE_KEY_MAP = {
  addresses: 'useraddresses'
}

export default {
  name: 'Account',
  components: {
    EntityForm,
    EntityEditForm
  },
  data () {
    return {
      entityKey: ENTITY_KEY,
      entityIdKey: `${singularize(ENTITY_KEY)}Id`,
      subEntityTypeKeyMap: SUBENTITY_TYPE_KEY_MAP,
      subEntityTypeKeys: Object.keys(SUBENTITY_TYPE_KEY_MAP),
      subEntityTypeAddFormStates: Object.keys(SUBENTITY_TYPE_KEY_MAP).reduce((subEntityTypesStates, tKey) => Object.assign(subEntityTypesStates, { [tKey]: false }), {})
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    entity () {
      return this.$store.getters[`${this.entityKey}/getSelfUnwrapped`]
    },
    subEntityAllowed () {
      return this.$store.getters['profile/can']('seeUserProfileAddresses')
    },
    subEntityTypesIs () {
      return this.subEntityTypeKeys.reduce((subEntityTypesIs, tKey) => Object.assign(subEntityTypesIs, { [tKey]: this.$store.getters[`${SUBENTITY_TYPE_KEY_MAP[tKey]}/is`] }), {})
    },
    subEntityTypesDefinitions () {
      return this.subEntityTypeKeys.reduce((subEntityTypesDefinitions, tKey) => Object.assign(subEntityTypesDefinitions, { [tKey]: this.$store.getters[`${SUBENTITY_TYPE_KEY_MAP[tKey]}/definition`] }), {})
    },
    subEntityTypes () {
      return this.subEntityTypeKeys.reduce((subEntityTypes, tKey) => Object.assign(subEntityTypes, { [tKey]: this.$store.getters[`${SUBENTITY_TYPE_KEY_MAP[tKey]}/getUnwrapped`] }), {})
    }
  },
  methods: {
    getEntity () {
      this.$store.dispatch(`${this.entityKey}/getSelf`)
        .then(this.getSubEntities)
    },
    getSubEntities () {
      this.subEntityTypeKeys
        .forEach(tKey => {
          this.$store.dispatch(`${SUBENTITY_TYPE_KEY_MAP[tKey]}/getAll`, { by: { name: this.entityKey, id: this.entity.id } })
        })
    },
    toggleSubEntityAddForm (tKey) {
      this.subEntityTypeAddFormStates[tKey] = !this.subEntityTypeAddFormStates[tKey]
    },
    createEntity (entityKey, formData) {
      this.$store.dispatch(`${entityKey}/createEntity`, Object.assign({ [this.entityIdKey]: this.entity.id }, formData.controls))
    },
    updateEntity (entityKey, entity) {
      this.$store.dispatch(`${entityKey}/updateEntity`, entity)
    },
    deleteEntity (entityKey, entity) {
      this.$store.dispatch(`${entityKey}/removeEntity`, entity)
    }
  },
  created () {
    this.getEntity()
  }
}
</script>

<style lang="scss">
$account-subentity-addform-gap: $spacer * 2 !default;
$account-subentity-actions-gap: $spacer * 0.25 !default;

.account {
  .subentity-type {
    .subentity-addform {
      margin-top: $account-subentity-addform-gap;
    }

    .subentity-actions {
      display: flex;
      justify-content: flex-end;
      margin-top: $account-subentity-actions-gap;
    }
  }
}
</style>
