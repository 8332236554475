<template>
  <entity-form
    id="InviteForm"
    :isBusy="is.loading"
    :entityDefinition="definition"
    :entityValues="values"
    :definitionModifier="definitionMapper"
    inline
    showButtons
    :buttonSizes="{ submit: 'xs', reset: 'xs' }"
    @form:submit="onSubmit"
  >
    <template #submit><lazy-icon icon="save"/> {{ $t(`${tPath}.submitbutton`) }}</template>
  </entity-form>
</template>

<script>
import EntityForm from '@/components/private/forms/EntityForm'

const ENTITY_KEY = 'users'
const RELATION_KEY = 'userlocations'
const INVITE_PROPERTIES = ['email', 'language']

export default {
  name: 'InviteForm',
  components: {
    EntityForm
  },
  props: {
    entityId: {
      type: String,
      required: true
    },
    initialValues: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    is () {
      return this.$store.getters[`${ENTITY_KEY}/is`]
    },
    definition () {
      return this.$store.getters[`${ENTITY_KEY}/definition`]
    },
    values () {
      return Object.keys(this.initialValues)
        .filter(vKey => this.definition.$properties[vKey] !== undefined)
        .reduce((values, vKey) => Object.assign(values, { [vKey]: this.initialValues[vKey] || null }), {})
    }
  },
  methods: {
    definitionMapper (definition = {}) {
      definition.properties = INVITE_PROPERTIES
        .filter(pKey => this.definition.$properties[pKey] !== undefined)
        .map(pKey => {
          const propertyDefinition = this.definition.$properties[pKey]
          propertyDefinition.settings.size = 'xs'

          return propertyDefinition
        })

      return definition
    },
    onSubmit (formData) {
      this.$store.dispatch(`${RELATION_KEY}/createInvitation`, {
        locationId: this.entityId,
        invite: formData.controls
      })
    }
  }
}
</script>

<style lang="scss"></style>
