<template functional>
  <div :id="data.id" :class="['user-line', { 'inline': props.inline }, data.class, data.staticClass]" :style="data.style" v-bind="data.attrs">
    <div>
      <slot name="customuser" v-bind="{ user: props.user }">
        <strong v-if="Object.keys(props.user).length > 0">{{ props.user.firstname }} {{ props.user.lastname }}</strong>
        <template v-if="props.user.email">, <a :href="`mailto:${props.user.email}`">{{ props.user.email }}</a></template>
      </slot>
    </div>

    <slot/>
  </div>
</template>

<script>
export default {
  name: 'UserLine',
  props: {
    user: {
      type: Object,
      default: () => ({})
    },
    inline: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
$userline-padding-y: $spacer * 0.5 !default;
$userline-padding-x: 0 !default;
$userline-border: map-get($borders, 'base') !default;

.user-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: $userline-padding-y $userline-padding-x;
  border-top: $userline-border;
  border-bottom: $userline-border;

  + .user-line {
    margin-top: nth($userline-border, 1) * -1;
  }

  &.inline {
    padding: 0;
    border: 0 none;

    + .user-line {
      margin-top: 0;
    }
  }
}
</style>
