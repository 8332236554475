<template>
  <section :class="['order-summary', { 'show-items': showItems }]">
    <header class="order-header">
      <b-row align-v="center">
        <b-col>
          <h6 v-if="order.configuration.orderType === 'Quote'">{{ $t(`${tPath}.quotetitle`, { orderId: order.orderId, orderCreation: order.created ? $d(new Date(order.created), 'date') : '' }) }}</h6>
          <h6 v-else>{{ $t(`${tPath}.title`, { orderId: order.orderId, orderCreation: order.created ? $d(new Date(order.created), 'date') : '' }) }}</h6>
        </b-col>
        <b-col v-if="!$root.isMobile" cols="auto">
          <b-button variant="outline-control" :href="`${window.location.pathname}/${order.orderId}`">{{ $t(`${tPath}.gotodetail`) }} <lazy-icon icon="arrow-long-right" /></b-button>
        </b-col>
      </b-row>

      <dl v-if="order.configuration.commissionText">
        <div>
          <dt>{{ $t(`${tPath}.commissiontextlabel`) }}</dt>
          <dd>{{ order.configuration.commissionText }}</dd>
        </div>
      </dl>

      <dl v-if="order.configuration.reseller">
        <div>
          <dt>{{ $t(`${tPath}.orderedby`) }}</dt>
          <dd>{{ order.configuration.user.email }}</dd>
        </div>
      </dl>

      <dl>
        <div>
          <dt>{{ $t(`${tPath}.statuslabel`) }}</dt>
          <dd>{{ $t(`${tPath}.status.${order.orderState.toLowerCase()}`) }}</dd>
        </div>

        <div>
          <dt>{{ $t(`${tPath}.deliverytypelabel`) }}</dt>
          <dd>{{ $t(`${tPath}.deliverytype.${order.configuration.deliveryType.toLowerCase()}`) }}</dd>
        </div>

        <div>
          <template v-if="!$root.isNullOrEmpty(order.configuration.paymentType)">
            <dt>{{ $t(`${tPath}.paymenttypelabel`) }}</dt>
            <dd>{{ $t(`${tPath}.paymenttype.${order.configuration.paymentType.toLowerCase()}`) }}</dd>
          </template>

          <template v-if="!$root.isNullOrEmpty(order.configuration.storeClerkPaymentType)">
            <dt>{{ $t(`${tPath}.storeclerkpaymenttype.title`) }}</dt>
            <dd>{{ $t(`${tPath}.storeclerkpaymenttype.${order.configuration.storeClerkPaymentType.toLowerCase()}`) }}</dd>
          </template>
        </div>

        <div v-if="order.configuration.primarySellerName">
          <dt>{{ $t(`${tPath}.primarysellername`) }}</dt>
          <dd><a href="mailto:verkauf@hunn.ch">{{ order.configuration.primarySellerName }} (verkauf@hunn.ch)</a></dd>
        </div>

        <div>
          <dt><span v-html="$t(`${tPath}.totalpricelabel`)"></span></dt>
          <dd>
            <i18n-n :value="order.executedBasket.priceDetail.totalPrice" :format="{ key: 'currency', currency: $t(`${tPath}.currency`) }">
              <template #currency="slotProps"><span class="currency font-sm">{{ slotProps.currency }}</span></template>
              <template #integer="slotProps"><span class="integer">{{ slotProps.integer }}</span></template>
              <template #group="slotProps"><span class="group">{{ slotProps.group }}</span></template>
              <template #decimal="slotProps"><span class="decimal">{{ slotProps.decimal }}</span></template>
              <template #fraction="slotProps"><span class="fraction">{{ slotProps.fraction }}</span></template>
            </i18n-n>
          </dd>
        </div>
      </dl>
      <b-button v-if="$root.isMobile" variant="outline-control" :href="`${window.location.pathname}/${order.orderId}`" block>{{ $t(`${tPath}.gotodetail`) }} <lazy-icon icon="arrow-long-right" /></b-button>
    </header>

    <div v-if="showItems" class="order-items">
      <div v-for="item in order.executedBasket.items" :key="item.basketItemId" class="item">
        <img class="item-image" v-if="item.product.image" :src="item.product.image" :alt="item.product.model" />
        <aspect-ratio v-else ratio="4x3" class="item-image is-fallback-image">
          <lazy-icon icon="images" />
        </aspect-ratio>

        <div class="item-name"><strong class="text-uppercase">{{ item.product.model }}</strong> {{ item.product.name }}</div>

        <div class="item-quantity">{{ $t(`${tPath}.itemquantity`, { quantity: item.quantity }) }}</div>
      </div>
    </div>
  </section>
</template>

<script>
import AspectRatio from '@/components/private/AspectRatio';

export default {
  name: 'OrderSummary',
  components: {
    AspectRatio
  },
  props: {
    order: {
      type: Object,
      required: true
    },
    showItems: {
      type: Boolean,
      default: false
    }
  }
}
</script>

<style lang="scss">
// mobile
$ordersummary-gap: $spacer * 1.5 !default;
$ordersummary-header-padding-y: $component-padding-x !default;
$ordersummary-header-padding-x: $component-padding-x !default;
$ordersummary-header-bg: $beige !default;
$ordersummary-header-border-radius: $border-radius !default;

$ordersummary-header-descriptionlist-grid-gutter: $spacer * 0.75 !default;
$ordersummary-header-descriptionlist-grid-columns: auto auto !default;
$ordersummary-header-descriptionlist-gap: $spacer !default;
$ordersummary-header-descriptionlist-line-height: 1.2 !default;
$ordersummary-header-descriptionlist-item-title-font-size: $font-size-sm !default;
$ordersummary-header-descriptionlist-item-title-font-weight: inherit !default;
$ordersummary-header-descriptionlist-item-title-color: $dark !default;
$ordersummary-header-descriptionlist-item-data-font-size: inherit !default;
$ordersummary-header-descriptionlist-item-data-font-weight: inherit !default;
$ordersummary-header-descriptionlist-item-data-color: inherit !default;

// tablet
$ordersummary-tablet: $tablet-breakpoint !default;

$ordersummary-tablet-items-border: map-get($borders, 'bold') !default;

$ordersummary-tablet-items-item-grid-gutter: $spacer * 0.75 !default;
$ordersummary-tablet-items-item-grid-columns: min-content auto max-content !default;
$ordersummary-tablet-items-item-padding-y: $ordersummary-header-padding-y * 0.5 !default;
$ordersummary-tablet-items-item-padding-x: $ordersummary-header-padding-x !default;
$ordersummary-tablet-items-item-border: map-get($borders, 'base') !default;
$ordersummary-tablet-items-item-img-width: 75px !default;
$ordersummary-tablet-items-item-img-fallback-color: $imgfallback !default;

.order-summary {
  margin-bottom: $ordersummary-gap;

  .order-header {
    padding: $ordersummary-header-padding-y $ordersummary-header-padding-x;
    background-color: $ordersummary-header-bg;
    border-radius: $ordersummary-header-border-radius;

    dl {
      display: grid;
      grid-gap: $ordersummary-header-descriptionlist-grid-gutter;
      grid-template-columns: $ordersummary-header-descriptionlist-grid-columns;
      margin: $ordersummary-header-descriptionlist-gap 0;
      line-height: $ordersummary-header-descriptionlist-line-height;

      &:last-child {
        margin-bottom: 0;
      }

      dt {
        margin: 0;
        font-size: $ordersummary-header-descriptionlist-item-title-font-size;
        font-weight: $ordersummary-header-descriptionlist-item-title-font-weight;
        color: $ordersummary-header-descriptionlist-item-title-color;
      }

      dd {
        margin: 0;
        font-size: $ordersummary-header-descriptionlist-item-data-font-size;
        font-weight: $ordersummary-header-descriptionlist-item-data-font-weight;
        color: $ordersummary-header-descriptionlist-item-data-color;
      }
    }
  }

  .order-items {
    display: none;
  }

  @include media-breakpoint-up($ordersummary-tablet) {
    .order-header {
      dl {
        grid-template-columns: auto;
        grid-auto-flow: column;
      }
    }

    .order-items {
      display: block;
      border-top: $ordersummary-tablet-items-border;

      .item {
        display: grid;
        grid-gap: $ordersummary-tablet-items-item-grid-gutter;
        grid-template-columns: $ordersummary-tablet-items-item-grid-columns;
        align-items: center;
        padding: $ordersummary-tablet-items-item-padding-y $ordersummary-tablet-items-item-padding-x;
        border-top: $ordersummary-tablet-items-item-border;
        border-bottom: $ordersummary-tablet-items-item-border;

        .item-image {
          width: $ordersummary-tablet-items-item-img-width;
          max-width: unset;

          .bi {
            width: 100%;
            height: 100%;
            color: $ordersummary-tablet-items-item-img-fallback-color;
          }
        }
      }
    }

    &.show-items {
      .order-header {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }

      &:last-of-type {
        margin-bottom: 0;

        .order-items {
          .item {
            border-bottom: none;
          }
        }

        & + hr {
          margin-top: 0;
        }
      }
    }
  }
}
</style>
