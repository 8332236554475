<template>
  <div class="account-company">
    <h1 class="h4">{{ $t(`${tPath}.title`) }}</h1>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.company.title`) }}</h2>
    <entity-edit-form
      :isBusy="relationIs.loading || is.loading"
      :entityKey="entityKey"
      :entityId="entityId || ''"
      :translationsPath="`${tPath}.company.editform`"
      :allowEdit="false"
    />

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.deliveryaddresses.title`) }}</h2>
    <loader v-if="relationIs.initial || is.initial || subEntityTypesIs.addresses.initial"/>
    <div v-else>
      <address-line v-for="address in companyDeliveryAddresses" :key="address.id" v-bind="address"/>
    </div>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.invoiceaddresses.title`) }}</h2>
    <loader v-if="relationIs.initial || is.initial || subEntityTypesIs.addresses.initial"/>
    <div v-else>
      <address-line v-for="address in companyInvoiceAddresses" :key="address.id" v-bind="address"/>
    </div>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.locationusers.title`) }}</h2>
    <loader v-if="usersIs.initial || relationIs.initial || is.initial || subEntityTypesIs.locationusers.initial"/>
    <div v-else>
      <user-line v-for="user in companyUsers" :key="user.id" :user="user.entity">
        <a v-if="!user.isSelf" href="#" @click="removeCompanyUser(user.relation)" class="delete"><lazy-icon icon="trash"/></a>
      </user-line>
    </div>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.inventedusers.title`) }}</h2>
    <loader v-if="usersIs.initial || relationIs.initial || is.initial || subEntityTypesIs.locationusers.initial"/>
    <div v-else>
      <user-line v-for="invitation in companyUserInvitations" :key="invitation.id">
        <template #customuser>
          <i18n :path="`${tPath}.inventedusers.invitedby`" tag="div">
            <template #user><strong>{{ invitation.email }}</strong></template>
            <template #invitedby><a :href="`mailto:${invitation.invitedBy}`">{{ invitation.invitedBy }}</a></template>
          </i18n>
        </template>

        <div class="actions">
          <button @click.prevent="reInviteCompanyUserInvitation(invitation)" :disabled="!invitation.canReInvite" :class="['reinvite','mr-2', 'btn-link', { 'disabled':!invitation.canReInvite }]" :title="$t(`${tPath}.actions.reinvite${(invitation.canReInvite ? 'yes' : 'no')}`)">
            <lazy-icon icon="b-arrow-repeat"/>
          </button>
          <button @click.prevent="removeCompanyUserInvitation(invitation)" class="btn-link delete" :title="$t(`${tPath}.actions.remove`)">
            <lazy-icon icon="trash"/>
          </button>
        </div>
      </user-line>
    </div>

    <h2 class="h6 text-muted mt-5">{{ $t(`${tPath}.inventusers.title`) }}</h2>
    <loader v-if="usersIs.initial || relationIs.initial || is.initial"/>
    <invite-form v-else :entityId="entityId" :initialValues="{ language: $i18n.locale }" :translationsPath="`${tPath}.inventusers`"/>
  </div>
</template>

<script>
import { USER_ADDRESS_TYPES } from '@/constants'

import { singularize } from '@/assets/js/helper/string'
import { unwrap } from '@/assets/js/helper/entity'

import Loader from '@/components/private/Loader'
import EntityEditForm from '@/components/private/forms/EntityEditForm'
import AddressLine from '@/components/private/AddressLine'
import UserLine from '@/components/private/UserLine'
import InviteForm from '@/components/private/forms/InviteForm'

const USER_KEY = 'users'
const RELATION_KEY = 'userlocations'
const ENTITY_KEY = 'locations'
const SUBENTITY_TYPE_KEY_MAP = {
  addresses: 'locationaddresses',
  locationusers: 'userlocations'
}

export default {
  name: 'AccountCompany',
  components: {
    Loader,
    EntityEditForm,
    AddressLine,
    UserLine,
    InviteForm
  },
  data () {
    return {
      userKey: USER_KEY,
      userId: this.$store.getters['profile/getUserId'],

      relationKey: RELATION_KEY,
      relationUserIdKey: `${singularize(USER_KEY)}Id`,
      relationEntityIdKey: `${singularize(ENTITY_KEY)}Id`,

      entityKey: ENTITY_KEY,
      entityId: null,

      subEntityTypeKeys: Object.keys(SUBENTITY_TYPE_KEY_MAP)
    }
  },
  computed: {
    usersIs () {
      return this.$store.getters[`${this.userKey}/is`]
    },
    relationIs () {
      return this.$store.getters[`${this.relationKey}/is`]
    },
    is () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    entity () {
      return this.$store.getters[`${this.entityKey}/getEntityUnwrapped`](this.entityId)
    },
    subEntityTypesIs () {
      return this.subEntityTypeKeys.reduce((subEntityTypesIs, tKey) => Object.assign(subEntityTypesIs, { [tKey]: this.$store.getters[`${SUBENTITY_TYPE_KEY_MAP[tKey]}/is`] }), {})
    },
    subEntityTypesDefinitions () {
      return this.subEntityTypeKeys.reduce((subEntityTypesDefinitions, tKey) => Object.assign(subEntityTypesDefinitions, { [tKey]: this.$store.getters[`${SUBENTITY_TYPE_KEY_MAP[tKey]}/definition`] }), {})
    },
    subEntityTypes () {
      return this.subEntityTypeKeys.reduce((subEntityTypes, tKey) => Object.assign(subEntityTypes, { [tKey]: this.$store.getters[`${SUBENTITY_TYPE_KEY_MAP[tKey]}/getUnwrapped`] }), {})
    },
    companyDeliveryAddresses () {
      const deliveryAddresses = this.subEntityTypes.addresses.filter(a => a.type === USER_ADDRESS_TYPES.delivery.type)

      if (deliveryAddresses.length > 0) {
        return deliveryAddresses.map(a => ({ user: { firstname: this.entity.name }, address: a }))
      }

      return [{
        user: { firstname: this.entity.name },
        address: this.subEntityTypes.addresses.find(a => a.type === USER_ADDRESS_TYPES.default.type)
      }]
    },
    companyInvoiceAddresses() {
      const invoiceAddresses = this.subEntityTypes.addresses.filter(a => a.type === USER_ADDRESS_TYPES.invoice.type)

      if (invoiceAddresses.length > 0) {
        return invoiceAddresses.map(a => ({ user: { firstname: this.entity.name }, address: a }))
      }

      return [{
        user: { firstname: this.entity.name },
        address: this.subEntityTypes.addresses.find(a => a.type === USER_ADDRESS_TYPES.default.type)
      }]
    },
    companyUsers () {
      return this.subEntityTypes.locationusers
        .map(locationUser => ({
          relation: locationUser,
          entity: this.$store.getters[`${this.userKey}/getUnwrapped`].find(u => locationUser[this.relationUserIdKey] === u.id) ?? {},
          isSelf: locationUser[this.relationUserIdKey] === this.userId
        }))
    },
    companyUserInvitations () {
      return this.$store.getters[`${this.relationKey}/getInvitations`]
    }
  },
  methods: {
    getRelations () {
      // this will also load every related entity (in this case: related locations)
      this.$store.dispatch(`${this.relationKey}/getAll`, { by: { name: 'users', id: this.userId } })
        .then(response => {
          this.entityId = unwrap(response[0])[this.relationEntityIdKey]
          this.getSubEntities()
          this.getInvitations()
        })
    },
    getSubEntities () {
      this.subEntityTypeKeys
        .forEach(tKey => {
          this.$store.dispatch(`${SUBENTITY_TYPE_KEY_MAP[tKey]}/getAll`, { by: { name: this.entityKey, id: this.entityId } })
        })
    },
    getInvitations () {
      this.$store.dispatch(`${this.relationKey}/getInvitations`, this.entityId)
    },
    removeCompanyUser (relation = {}) {
      this.$store.dispatch(`${this.relationKey}/removeEntity`, relation)
    },
    removeCompanyUserInvitation (invitation = {}) {
      this.$store.dispatch(`${this.relationKey}/removeInvitation`, {
        locationId: this.entityId,
        invitation
      })
    },
    reInviteCompanyUserInvitation (invitation = {}) {
      this.$store.dispatch(`${this.relationKey}/reInviteUser`, {
        locationId: this.entityId,
        invitation
      })
    }
  },
  created () {
    this.getRelations()
  }
}
</script>

<style lang="scss">
.account-company {
  .actions {
    .btn-link {
      border: none;
      background: none;

      &.disabled {
        pointer-events: auto;
        cursor: unset;
      }
    }
  }
}
</style>
