<template>
  <div class="account-orders">
    <loader v-if="is.loading || subEntityTypesIs.orders.loading" variant="primary" />

    <template v-else>
      <b-row class="mb-2">
        <b-col cols="12" md="auto">
          <h2 class="lead">{{ $t(`${tPath}.order.title`) }}</h2>
        </b-col>
        <b-col cols="12" md="auto" v-if="allOrdersLink" class="ml-md-auto text-md-right">
          <a :href="allOrdersLink" class="font-medium">{{ $t(`${tPath}.order.showallorders`) }} <lazy-icon icon="arrow-long-right" /></a>
        </b-col>
      </b-row>
      <template v-if="orders.length">
        <order-summary v-for="order in orders" :key="order.orderId" :order="order" showItems :translationsPath="`${tPath}.ordersummary`" />
      </template>
      <div v-else class="text-center bg-light p-5">{{ $t(`${tPath}.order.noordersfound`) }}</div>
    </template>
  </div>
</template>

<script>
import { singularize } from '@/assets/js/helper/string'

import Loader from '@/components/private/Loader'
import OrderSummary from '@/components/private/OrderSummary'

const ENTITY_KEY = 'users'
const SUBENTITY_TYPE_KEY_MAP = {
  orders: 'orderaccessor'
}

export default {
  name: 'AccountOverviewOrders',
  components: {
    Loader,
    OrderSummary
  },
  props: {
    allOrdersLink: {
      type: String,
      default: null
    },
    orderAmountToShow: {
      type: Number,
      default: 10
    }
  },
  data() {
    return {
      entityKey: ENTITY_KEY,
      entityIdKey: `${singularize(ENTITY_KEY)}Id`,
      subEntityTypeKeyMap: SUBENTITY_TYPE_KEY_MAP,
      subEntityTypeKeys: Object.keys(SUBENTITY_TYPE_KEY_MAP)
    }
  },
  computed: {
    is() {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    entity() {
      return this.$store.getters[`${this.entityKey}/getSelfUnwrapped`]
    },
    subEntityTypesIs() {
      return this.subEntityTypeKeys.reduce(
        (subEntityTypesIs, tKey) => Object.assign(subEntityTypesIs, { [tKey]: this.$store.getters[`${this.subEntityTypeKeyMap[tKey]}/is`] }),
        {}
      )
    },
    subEntityTypes() {
      return this.subEntityTypeKeys.reduce(
        (subEntityTypes, tKey) => Object.assign(subEntityTypes, { [tKey]: this.$store.getters[`${this.subEntityTypeKeyMap[tKey]}/get`] }),
        {}
      )
    },
    orders() {
      return this.subEntityTypes.orders.slice(0, this.orderAmountToShow)
    }
  },
  methods: {
    getEntity() {
      this.$store.dispatch(`${this.entityKey}/getSelf`).then(this.getSubEntities)
    },
    getSubEntities() {
      this.subEntityTypeKeys.forEach((tKey) => {
        this.$store.dispatch(`${this.subEntityTypeKeyMap[tKey]}/getAll`, { by: { name: this.entityKey, id: this.entity.id } })
      })
    }
  },
  created() {
    this.getEntity()
  }
}
</script>

<style lang="scss">
.account-orders {
}
</style>
