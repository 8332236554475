<template>
  <nav class="account-menu">
    <div>
      <header class="menu-header">
        <div class="menu-close">
          <slot name="menuClose" />
        </div>

        <div :class="['account-name', { 'mb-2': profile.user.userExternalId }]">{{ profile.user.firstname }} {{ profile.user.lastname }}</div>
        <i18n class="account-number" tag="div" :path="`${tPath}.menu.header.clientnumber`" v-if="profile.user.userExternalId">
          <template #number>{{ profile.user.userExternalId }}</template>
        </i18n>
      </header>

      <ul class="menu-items">
        <li
          v-for="(item, iIndex) in menu"
          :key="iIndex"
          :class="{ active: item.active }"
          role="presentation"
        >
          <hr v-if="item.type === 'divider'" role="seperator"/>
          <a v-else v-bind="item" role="menuitem">{{ item.text }}</a>
        </li>
      </ul>

      <ul class="menu-items">
          <li :class="{ active: logout.active }" role="presentation">
            <a v-bind="logout" role="menuitem">{{ logout.text }}</a>
          </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'AccountMenu',
  props: {
    logout: {
      type: Object,
      default: () => ({})
    },
    menu: {
      type: Array,
      default: () => ([])
    }
  },
  computed: {
    profile () {
      return this.$store.getters['profile/get']
    }
  }
}
</script>

<style lang="scss">
$accountmenu-bg: $beige !default;
$accountmenu-border-radius: $border-radius !default;

$accountmenu-header-gap: 3px !default;
$accountmenu-header-padding-y: $dropdown-item-padding-y * 3 !default;
$accountmenu-header-padding-x: $dropdown-item-padding-x !default;
$accountmenu-header-text-align: center !default;

$accountmenu-close-size: $spacer * 1.25 !default;

$accountmenu-items-gap: $accountmenu-header-gap !default;
$accountmenu-items-padding-y: $dropdown-divider-margin-y !default;
$accountmenu-items-padding-x: 0 !default;

$accountmenu-items-item-padding-y: $dropdown-item-padding-y * 1.5 !default;
$accountmenu-items-item-padding-x: $dropdown-item-padding-x * 1.5 !default;
$accountmenu-items-item-font-size: inherit !default;
$accountmenu-items-item-font-weight: inherit !default;
$accountmenu-items-item-color: inherit !default;
$accountmenu-items-item-line-height: 1.1 !default;
$accountmenu-items-item-hover-border: set-nth(map-get($borders, 'bold'), 3, $primary) !default;
$accountmenu-items-item-hover-font-size: inherit !default;
$accountmenu-items-item-hover-font-weight: $font-weight-bold !default;
$accountmenu-items-item-hover-color: inherit !default;
$accountmenu-items-item-active-border: $accountmenu-items-item-hover-border !default;
$accountmenu-items-item-active-font-size: $accountmenu-items-item-hover-font-size !default;
$accountmenu-items-item-active-font-weight: $accountmenu-items-item-hover-font-weight !default;
$accountmenu-items-item-active-color: $accountmenu-items-item-hover-color !default;

$accountmenu-items-seperator-gap: $dropdown-divider-margin-y !default;

$accountmenu-accountname-font-size: $font-size-lg !default;
$accountmenu-accountname-font-weight: $font-weight-bold !default;
$accountmenu-accountname-color: inherit !default;
$accountmenu-accountname-line-height: 1.1 !default;

$accountmenu-accountnumber-font-size: $font-size-sm !default;
$accountmenu-accountnumber-font-weight: inherit !default;
$accountmenu-accountnumber-color: $dark !default;
$accountmenu-accountnumber-line-height: inherit !default;

.account-menu {
  background-color: $accountmenu-bg;
  border-radius: $accountmenu-border-radius;

  .menu-header {
    position: relative;
    border-bottom: $accountmenu-header-gap solid $body-bg;
    padding: $accountmenu-header-padding-y $accountmenu-header-padding-x;
    text-align: $accountmenu-header-text-align;

    .menu-close {
      position: absolute;
      top: $accountmenu-close-size * 0.5;
      right: $accountmenu-close-size * 0.5;

      * {
        display: block;
        font-size: $accountmenu-close-size;
        color: inherit;
        line-height: 1;
        text-decoration: none;
      }
    }
  }

  .menu-items {
    @include list-unstyled();
    margin: 0;
    padding: $accountmenu-items-padding-y $accountmenu-items-padding-x;

    > li {
      hr {
        margin-top: $accountmenu-items-seperator-gap;
        margin-bottom: $accountmenu-items-seperator-gap;
      }

      a {
        display: block;
        position: relative;
        padding: $accountmenu-items-item-padding-y $accountmenu-items-item-padding-x;
        font-size: $accountmenu-items-item-font-size;
        font-weight: $accountmenu-items-item-font-weight;
        color: $accountmenu-items-item-color;
        line-height: $accountmenu-items-item-line-height;
        text-decoration: none;

        &:before {
          display: block;
          content: '';
          position: absolute;
          top: $accountmenu-items-item-padding-y;
          bottom: $accountmenu-items-item-padding-y;
          left: $accountmenu-items-item-padding-x * 0.5;
        }

        &:hover {
          font-size: $accountmenu-items-item-hover-font-size;
          font-weight: $accountmenu-items-item-hover-font-weight;
          color: $accountmenu-items-item-hover-color;

          &:before {
            border-left: $accountmenu-items-item-hover-border;
          }
        }
      }

      &.active {
        a {
          font-size: $accountmenu-items-item-active-font-size;
          font-weight: $accountmenu-items-item-active-font-weight;
          color: $accountmenu-items-item-active-color;

          &:before {
            border-left: $accountmenu-items-item-active-border;
          }
        }
      }
    }

    + .menu-items {
      border-top: $accountmenu-items-gap solid $body-bg;
    }
  }

  .account-name {
    font-size: $accountmenu-accountname-font-size;
    font-weight: $accountmenu-accountname-font-weight;
    color: $accountmenu-accountname-color;
    line-height: $accountmenu-accountname-line-height;
  }

  .account-number {
    font-size: $accountmenu-accountnumber-font-size;
    font-weight: $accountmenu-accountnumber-font-weight;
    color: $accountmenu-accountnumber-color;
    line-height: $accountmenu-accountnumber-line-height;
  }
}
</style>
