<template>
  <b-form :class="['entity-edit-form', `form-mode-${form.mode}`, { 'inline': inline }]" novalidate v-on:submit.prevent>
    <loader v-if="validatorsIs.loading || entityIs.loading"/>

    <b-form-row v-else class="form-controls">
      <template v-if="form.mode === form.modes.write || !inline">
        <b-col
          v-for="property in entityProperties"
          :key="property.id"
          :[$root.mt.key]="property._grid.cols"
          class="controls-col"
        >
          <template v-if="property.visible">
            <template v-if="form.mode === form.modes.write">
              <b-form-group
                v-if="property.control.readonly"
                :label-for="property.control.id"
                :label="property.control.label"
              >
              <div class="input-group">
                  <div class="form-control" readonly>
                    <div v-html="valueFormatter(entityDefinition, property.name, property.control.value).html"/>
                  </div>
                </div>
              </b-form-group>

              <component
                v-else
                :is="property.control.is"
                v-model="property.control.value"
                v-bind="property.control"

                @input="$value => controlUpdate(property, $value)"
                @change="$value => controlUpdate(property, $value)"
              />
            </template>

            <b-form-group
              v-else-if="property.control.value"
              :label-for="property.control.id"
              :label="property.control.label"
            >
              <div class="font-bold">
                <span v-html="valueFormatter(entityDefinition, property.name, property.control.value).html"/>
              </div>
            </b-form-group>
          </template>
        </b-col>
      </template>

      <b-col v-else cols="auto" class="controls-col">
        <b-form-group
          v-for="(property, pIndex) in entityProperties.filter(p => p.visible && p.control.value)"
          :key="property.id"
          :label-for="property.control.id"
          :label="property.control.label"
          label-sr-only
        >
          <span v-if="pIndex > 0">,&nbsp;</span>
          <span v-html="valueFormatter(entityDefinition, property.name, property.control.value).html"/>
        </b-form-group>
      </b-col>

      <b-col v-if="allowEdit || allowDeletion" :cols="form.mode !== form.modes.write && inline ? 'auto' : 12" class="form-action">
          <div v-if="form.mode === form.modes.write" class="btn-list">
            <b-button variant="outline-control" @click="abortWriteMode()">
              <lazy-icon icon="remove"/> {{ $t(`${tPath}.action.abort`) }}
            </b-button>
            <b-button variant="primary" @click="updateEntity()">
              <lazy-icon icon="save"/> {{ $t(`${tPath}.action.save`) }}
            </b-button>
          </div>

          <div v-else class="link-list">
            <a v-if="allowEdit" href="#" @click.prevent="setMode(form.modes.write)">
              <lazy-icon icon="pen"/><span :class="{ 'sr-only': inline }"> {{ $t(`${tPath}.action.edit`) }}</span>
            </a>

            <a v-if="allowDeletion" href="#" @click.prevent="deleteEntity()">
              <lazy-icon icon="trash"/><span :class="{ 'sr-only': inline }"> {{ $t(`${tPath}.action.delete`) }}</span>
            </a>
          </div>
      </b-col>
    </b-form-row>
  </b-form>
</template>

<script>
import Loader from '@/components/private/Loader'

import { valueFormatter, propertyMapper, preProcessEntityProperties } from '@/assets/js/helper/entity'

import ControlInput from '@/components/private/forms/ControlInput'
import ControlSelect from '@/components/private/forms/ControlSelect'
import ControlTextarea from '@/components/private/forms/ControlTextarea'
import ControlCheckbox from '@/components/private/forms/ControlCheckbox'
import ControlRadio from '@/components/private/forms/ControlRadio'
import ControlFile from '@/components/private/forms/ControlFile'
// import ControlFiles from '@/components/private/forms/ControlFiles'
import ControlDatepicker from '@/components/private/forms/ControlDatepicker'
import ControlSpinbutton from '@/components/private/forms/ControlSpinbutton'
import ControlAutocomplete from '@/components/private/forms/ControlAutocomplete'
import ControlUnknown from '@/components/private/forms/ControlUnknown'

const FORM_MODES = {
  read: 'read',
  write: 'write'
}
const ALLOWED_FORM_MODES = Object.values(FORM_MODES)

export default {
  name: 'EntityEditForm',
  components: {
    Loader,
    ControlInput,
    ControlSelect,
    ControlTextarea,
    ControlCheckbox,
    ControlRadio,
    ControlFile,
    // ControlFiles,
    ControlDatepicker,
    ControlSpinbutton,
    ControlAutocomplete,
    ControlUnknown
  },
  props: {
    entityKey: {
      type: String,
      required: true
    },
    entityId: {
      type: String,
      required: true
    },
    mode: {
      type: String,
      default: 'read'
    },
    inline: {
      type: Boolean,
      default: false
    },
    allowEdit: {
      type: Boolean,
      default: true
    },
    allowDeletion: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      form: {
        modes: FORM_MODES,
        mode: this.mode,
        controls: {}
      }
    }
  },
  validations () {
    return {
      form: {
        controls: Object.keys(this.form.controls)
          .filter(cKey => this.form.controls[cKey].visible)
          .reduce((controls, cKey) => Object.assign(controls, { [cKey]: { value: this.form.controls[cKey].control.validations } }), {})
      }
    }
  },
  computed: {
    validatorsIs () {
      return this.$store.getters['validators/is']
    },
    validators () {
      return this.$store.getters['validators/get']
    },
    entityIs () {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    entityDefinition () {
      return this.$store.getters[`${this.entityKey}/definition`]
    },
    entity () {
      return this.$store.getters[`${this.entityKey}/getEntity`](this.entityId)
    },
    entityProperties () {
      return propertyMapper(this.entityDefinition, this.$store.getters['gui/language:get'], this.validators, this.entity)
    }
  },
  methods: {
    valueFormatter,
    setMode (mode) {
      if (ALLOWED_FORM_MODES.includes(mode)) this.form.mode = mode

      if (this.form.mode === this.form.modes.write) {
        this.$nextTick(() => {
          this.$el.querySelector('input, select, textarea').focus()
        })
      }
    },
    abortWriteMode () {
      this.entityProperties.forEach(p => p.reset())
      this.setMode(this.form.modes.read)
    },
    controlUpdate (property, value) {
      this.form.controls[property.name].value = value
    },
    validate () {
      this.entityProperties.forEach(p => p.touch())
      this.$v.$touch()
    },
    updateEntity () {
      if (this.$v.$invalid) {
        this.validate()
      } else {
        const entityProperties = preProcessEntityProperties(this.entityProperties)
        const value = Object.assign({}, this.entity.value, entityProperties.reduce((properties, p) => Object.assign(properties, { [p.name]: p.control.value }), {}))

        this.$emit('form:update', value)
        this.entityProperties.forEach(p => p.update())
        this.setMode(this.form.modes.read)
      }
    },
    deleteEntity () {
      this.$emit('form:delete', Object.assign({}, this.entity.value, this.entityProperties.reduce((properties, p) => Object.assign(properties, { [p.name]: p.control.value }), {})))
      this.entityProperties.forEach(p => p.update())
      this.setMode(this.form.modes.read)
    }
  },
  created () {
    this.$store.dispatch('validators/get')
  },
  watch: {
    entityProperties: {
      immediate: true,
      deep: true,
      handler () {
        this.form.controls = this.entityProperties
          .reduce((controls, property) => Object.assign(controls, { [property.name]: property }), {})

        this.entityProperties
          .forEach(p => this.controlUpdate(p, p.control.value))
      }
    }
  }
}
</script>

<style lang="scss">
// mobile

$entityeditform-action-gap: $spacer !default;

$entityeditform-inline-padding-y: $spacer * 0.5 !default;
$entityeditform-inline-border-y: map-get($borders, 'base') !default;

// tablet

$entityeditform-tablet: $tablet-breakpoint !default;

.entity-edit-form {
  .form-controls {}

  .form-action {
    display: flex;
    justify-content: flex-end;
    margin-top: $entityeditform-action-gap;
  }

  &:not(.form-mode-write) {
    .form-controls {
      .controls-col {
        .form-group {
          label {
            margin-bottom: 0;
          }
        }
      }
    }
  }

  &.inline {
    padding-top: $entityeditform-inline-padding-y;
    padding-bottom: $entityeditform-inline-padding-y;
    border-top: $entityeditform-inline-border-y;
    border-bottom: $entityeditform-inline-border-y;

    &:not(.form-mode-write) {
      .form-controls {
        justify-content: space-between;
        align-items: center;

        .controls-col {
          display: flex;

          .form-group {
            margin-bottom: 0;
          }
        }
      }

      .form-action {
        margin-top: 0;
      }

      + .entity-edit-form {
        border-top: 0 none;
      }
    }
  }

  @include media-breakpoint-up($entityeditform-tablet) {
    [class*="col-md-12"] {
      .form-group {
        input:not([type="checkbox"]):not([type="radio"]),
        select {
          max-width: calc(50% - ($form-grid-gutter-width * 0.5));
        }
      }
    }
  }
}
</style>
