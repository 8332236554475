<template>
  <div class="account-orders">
    <b-row>
      <b-col :[$root.md.key]="6">
        <h1 class="h4">{{ $t(`${tPath}.title`) }}</h1>
      </b-col>
      <b-col :[$root.md.key]="6">
        <div :class="['search-form', { focus: form.focus }]" @focusin.capture="toggleFocus" @focusout.capture="toggleFocus">
          <b-form-input
            class="search-input"
            type="text"
            :placeholder="$t(`${tPath}.form.input.placeholder`)"
            v-model="form.value"
            :debounce="form.debounce"
            aria-autocomplete="list"
            @keydown.enter="submit"
            @keydown.esc="reset"
          />

          <span class="search-submit">
            <lazy-icon @click="reset" v-if="form.value" class="clear mr-1" icon="remove" />
            <lazy-icon icon="search" />
          </span>
        </div>
      </b-col>
    </b-row>

    <hr />

    <loader v-if="is.loading || subEntityTypesIs.orders.loading" variant="primary" />

    <template v-else>
      <div>
        <h2 class="lead">{{ $t(`${tPath}.ordersopen.title`) }}</h2>
        <order-summary v-for="order in ordersOpen" :key="order.orderId" :order="order" showItems :translationsPath="`${tPath}.ordersummary`" />
      </div>

      <hr />

      <div>
        <h2 class="lead">{{ $t(`${tPath}.ordersclosed.title`) }}</h2>
        <order-summary v-for="order in ordersClosed" :key="order.orderId" :order="order" :translationsPath="`${tPath}.ordersummary`" />
      </div>
    </template>
  </div>
</template>

<script>
import { ORDER_STATES } from '@/constants'

import { singularize } from '@/assets/js/helper/string'

import Loader from '@/components/private/Loader'
import OrderSummary from '@/components/private/OrderSummary'

const ENTITY_KEY = 'users'
const SUBENTITY_TYPE_KEY_MAP = {
  orders: 'orderaccessor'
}

const OPEN_ORDER_STATES = [ORDER_STATES.ordered, ORDER_STATES.processed]
const CLOSED_ORDER_STATES = [ORDER_STATES.closed]

export default {
  name: 'AccountOrders',
  components: {
    Loader,
    OrderSummary
  },
  data() {
    return {
      entityKey: ENTITY_KEY,
      entityIdKey: `${singularize(ENTITY_KEY)}Id`,
      subEntityTypeKeyMap: SUBENTITY_TYPE_KEY_MAP,
      subEntityTypeKeys: Object.keys(SUBENTITY_TYPE_KEY_MAP),
      form: {
        value: '',
        debounce: 200,
        focus: false
      }
    }
  },
  computed: {
    is() {
      return this.$store.getters[`${this.entityKey}/is`]
    },
    entity() {
      return this.$store.getters[`${this.entityKey}/getSelfUnwrapped`]
    },
    subEntityTypesIs() {
      return this.subEntityTypeKeys.reduce(
        (subEntityTypesIs, tKey) => Object.assign(subEntityTypesIs, { [tKey]: this.$store.getters[`${this.subEntityTypeKeyMap[tKey]}/is`] }),
        {}
      )
    },
    subEntityTypes() {
      return this.subEntityTypeKeys.reduce(
        (subEntityTypes, tKey) => Object.assign(subEntityTypes, { [tKey]: this.$store.getters[`${this.subEntityTypeKeyMap[tKey]}/get`] }),
        {}
      )
    },
    ordersOpen() {
      return this.filterBySearchForm(this.subEntityTypes.orders.filter((o) => OPEN_ORDER_STATES.includes(o.orderState)))
    },
    ordersClosed() {
      return this.filterBySearchForm(this.subEntityTypes.orders.filter((o) => CLOSED_ORDER_STATES.includes(o.orderState)))
    }
  },
  methods: {
    toggleFocus() {
      this.form.focus = !this.form.focus
    },
    reset() {
      this.form.value = ''
    },
    filterBySearchForm(orders) {
      if (!this.form.value) {
        return orders
      }

      const search = this.form.value.toLowerCase()

      return orders.filter(
        (o) =>
          o.orderId.includes(search) ||
          o.configuration.commissionText?.toLowerCase().includes(search) ||
          o.configuration.user.email?.toLowerCase().includes(search)
      )
    },
    getEntity() {
      this.$store.dispatch(`${this.entityKey}/getSelf`).then(this.getSubEntities)
    },
    getSubEntities() {
      this.subEntityTypeKeys.forEach((tKey) => {
        this.$store.dispatch(`${this.subEntityTypeKeyMap[tKey]}/getAll`, { by: { name: this.entityKey, id: this.entity.id } })
      })
    }
  },
  created() {
    this.getEntity()
  }
}
</script>

<style lang="scss">
// mobile
$headersearch-form-gap: $spacer * 0.5 !default;
$headersearch-form-height: $spacer * 2 !default;
$headersearch-form-border: map-get($borders, 'bold') !default;
$headersearch-form-focus-border: set-nth($headersearch-form-border, 3, $primary) !default;

$headersearch-form-submit-padding-y: 0 !default;
$headersearch-form-submit-padding-x: $headersearch-form-height * 0.33 !default;
$headersearch-form-focus-submit-color: $primary !default;

$headersearch-form-input-padding-y: $headersearch-form-submit-padding-y !default;
$headersearch-form-input-padding-x: $headersearch-form-submit-padding-x !default;
$headersearch-form-input-bg: $input-bg !default;
$headersearch-form-input-placeholder-color: $body-color !default;

// desktop
$headersearch-desktop-form-gap: $spacer * 0.5 !default;
$headersearch-desktop-form-width: 300px !default;

.account-orders {
  .search-form {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    margin: $headersearch-form-gap 0;
    height: $headersearch-form-height;
    background-color: $headersearch-form-input-bg;
    border: $headersearch-form-border;

    .search-input {
      flex: 1 1 100%;
      margin: 0;
      padding: $headersearch-form-input-padding-y $headersearch-form-input-padding-x;
      height: 100%;
      background: none;
      border: 0 none;
      outline: 0 none;
      font-family: inherit;
      font-size: inherit;
      font-weight: inherit;
      font-style: inherit;
      box-shadow: none;

      &::placeholder {
        color: $headersearch-form-input-placeholder-color;
      }

      &:last-child {
        padding-left: 0;
      }
    }

    .search-submit {
      flex: 0 0 auto;
      padding: $headersearch-form-submit-padding-y $headersearch-form-submit-padding-x;
      background: none;
      border: 0 none;

      .clear {
        cursor: pointer;
      }

      &:last-child {
        padding-left: 0;
      }
    }

    &.focus {
      border: $headersearch-form-focus-border;

      .search-submit {
        color: $headersearch-form-focus-submit-color;
      }
    }
  }
}
</style>
