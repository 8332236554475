<template>
  <div class="registration">
    <template v-if="!submitted">
      <h1 v-if="mode !== 'checkout'" class="h4">{{ $t(`${tPath}.title`) }}</h1>

      <b-row>
        <b-col v-bind="{ [$root.md.key]: ($slots['aside'] ? 8 : 12) }">

          <b-alert v-if="mode !== 'checkout'" variant="danger" :show="!formValid">
            <h2 class="h5 alert-heading"><lazy-icon icon="b-exclamation-circle"/> {{ $t(`${tPath}.errors.title`) }}</h2>

            <div>
              <div class="error-group">
                <div class="error">
                  {{ $t(`${tPath}.errors.globalerror`) }}
                </div>
              </div>
            </div>
          </b-alert>

          <div :class="['registration-form', { 'no-border-bottom': omitTerms  }]">
            <h2 class="h6 font-spacing-1">{{ $t(`${tPath}.user.title`) }}</h2>

            <entity-form
              :id="formIds.user"
              entityKey="users"
              :entityValues="formData.user"
              :lazy="mode === 'checkout'"
              :definitionModifier="def => modifyControlDefinitionVisibility('users', def)"
              @form:change="$value => setFormData('user', $value)"
              @form:touch="$isValid => setIsValid('user', $isValid)"
            />

            <entity-form
              :id="formIds.userAddresses"
              entityKey="useraddresses"
              :entityValues="formData.userAddresses[0]"
              :lazy="mode === 'checkout'"
              :definitionModifier="def => modifyControlDefinitionVisibility('useraddresses', def)"
              @form:change="$value => setFormData('userAddresses', $value)"
              @form:touch="$isValid => setIsValid('userAddresses', $isValid)"
            />

            <template v-if="!omitLogin">
              <hr/>

              <h2 class="h6 font-spacing-1">{{ $t(`${tPath}.login.title`) }}</h2>

              <controls-form
                :id="formIds.login"
                :controlDefinition="loginFormDefinition"
                :controlValues="formData.login"
                :lazy="mode === 'checkout'"
                :showButtons="false"
                @form:change="$value => setFormData('login', $value)"
                @form:touch="$isValid => setIsValid('login', $isValid)"
              />
            </template>
          </div>

          <div v-if="!omitTerms" class="registration-termsandpolicies">
            <p class="text-dark">{{ $t(`${tPath}.termsandpolicies.trustmessage`) }}</p>

            <controls-form
              :id="formIds.termsAndPolicies"
              :controlDefinition="termsAndPoliciesFormDefinition"
              :controlValues="formData.termsAndPolicies"
              :lazy="mode === 'checkout'"
              :showButtons="false"
              @form:change="$value => setFormData('termsAndPolicies', $value)"
              @form:touch="$isValid => setIsValid('termsAndPolicies', $isValid)"
            />
          </div>

          <div v-if="mode !== 'checkout'" class="registration-action">
            <b-button variant="primary" size="lg" block @click="submitForm">{{ $t(`${tPath}.submitbutton`) }}</b-button>
          </div>
        </b-col>

        <b-col v-if="$slots['aside']" v-bind="{ [$root.md.key]: 4, class: { 'mt-3': !$root.isDesktop } }">
          <slot name="aside" />
        </b-col>
      </b-row>
    </template>

    <template v-else>
      <loader v-if="!completionData"/>

      <template v-else>
        <slot name="completion" v-bind="completionData"/>
      </template>
    </template>
  </div>
</template>

<script>
import { COMPONENT_REGISTRATION_FORM_IDS, COMPONENT_REGISTRATION_FORMDATA_DEFAULT, COMPONENT_REGISTRATION_DEFAULT_TO_CHECKOUT_MAP, COMPONENT_REGISTRATION_HIDE_CONTROL_BY_NAME, COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP } from '@/constants'

import { copy } from '@/assets/js/helper/object'
import { getSearchParameters } from '@/assets/js/helper/url'
import { getNamedParentContext } from '@/assets/js/helper/vm'

import Loader from '@/components/private/Loader'
import ControlsForm from '@/components/private/forms/ControlsForm'
import EntityForm from '@/components/private/forms/EntityForm'
import CheckoutStep from '@/components/public/order/CheckoutStep'
import CheckoutOptionForm from '@/components/public/order/CheckoutOptionForm'

export default {
  name: 'Registration',
  components: {
    Loader,
    ControlsForm,
    EntityForm
  },
  props: {
    mode: {
      type: String,
      default: 'default'
    },
    omitLogin: {
      type: Boolean,
      default: false
    },
    omitTerms: {
      type: Boolean,
      default: false
    },
    initValues: {
      type: Object,
      default: () => ({})
    },
    emitChangesDirectly: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      submitted: false,
      formIds: Object.keys(COMPONENT_REGISTRATION_FORM_IDS).reduce((ids, iKey) => Object.assign(ids, { [iKey]: `${COMPONENT_REGISTRATION_FORM_IDS[iKey]}_Mode:${this.mode}_OmitLogin:${this.omitLogin}` }), {}),
      formData: Object.assign(copy(COMPONENT_REGISTRATION_FORMDATA_DEFAULT), { invitationCode: getSearchParameters('code') }),
      loginFormData: {},
      completionData: null,
      loginFormDefinition: {
        properties: [
          {
            attributeTypeDiscriminator: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.input.type,
            name: 'email',
            settings: {
              disabled: true,
              validators: ['emailValidator', 'requiredValidator']
            },
            translations: {
              [this.$store.getters['gui/language:get']]: {
                name: this.$t(`${this.tPath}.login.control.username.label`)
              }
            }
          },
          {
            attributeTypeDiscriminator: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.password.type,
            name: 'password',
            settings: {
              autocomplete: 'new-password',
              pattern: '^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[^a-zA-Z\\d]).{6,}$',
              validators: ['requiredValidator']
            },
            translations: {
              [this.$store.getters['gui/language:get']]: {
                name: this.$t(`${this.tPath}.login.control.password.label`),
                feedbacksInvalid: {
                  lowercase: this.$t(`${this.tPath}.login.control.password.feedback.invalid.lowercase`),
                  uppercase: this.$t(`${this.tPath}.login.control.password.feedback.invalid.uppercase`),
                  digit: this.$t(`${this.tPath}.login.control.password.feedback.invalid.digit`),
                  nonealphanumeric: this.$t(`${this.tPath}.login.control.password.feedback.invalid.nonealphanumeric`),
                  minLength: this.$t(`${this.tPath}.login.control.password.feedback.invalid.minlength`)
                }
              }
            }
          }
        ]
      },
      termsAndPoliciesFormDefinition: {
        properties: [
          {
            attributeTypeDiscriminator: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.boolean.type,
            name: 'termsOfUse',
            settings: {
              validators: ['requiredValidator']
            },
            translations: {
              [this.$store.getters['gui/language:get']]: {
                text: this.$t(`${this.tPath}.termsandpolicies.control.termsofuse.label`, { link: `<a href="${this.$t(`${this.tPath}.termsandpolicies.control.termsofuse.link.href`)}">${this.$t(`${this.tPath}.termsandpolicies.control.termsofuse.link.text`)}</a>` })
              }
            }
          },
          {
            attributeTypeDiscriminator: COMPONENT_CONTROL_CONTROL_DISCRIMINATOR_MAP.boolean.type,
            name: 'policiyAndSecurity',
            settings: {
              validators: ['requiredValidator']
            },
            translations: {
              [this.$store.getters['gui/language:get']]: {
                text: this.$t(`${this.tPath}.termsandpolicies.control.policyandsecurity.label`, { link: `<a href="${this.$t(`${this.tPath}.termsandpolicies.control.policyandsecurity.link.href`)}">${this.$t(`${this.tPath}.termsandpolicies.control.policyandsecurity.link.text`)}</a>` })
              }
            }
          }
        ]
      },
      formValidation: Object.keys(COMPONENT_REGISTRATION_FORM_IDS).reduce((formValidation, iKey) => Object.assign(formValidation, { [iKey]: true }), {})
    }
  },
  computed: {
    formValid () {
      return Object.keys(COMPONENT_REGISTRATION_FORM_IDS)
        .every(dKey => this.formValidation[dKey])
    }
  },
  methods: {
    getInitialData () {
      this.$store.dispatch('validators/get')

      if (this.formData.invitationCode) {
        this.$http({
          method: 'get',
          url: `/customer/api/Invitation/${this.formData.invitationCode}`
        })
          .then(response => {
            const invitationDataResult = response.data.result
            const invitationData = invitationDataResult.invitationData

            this.formData.invitationCode = invitationDataResult.invitationCode
            this.setFormData('user', { controls: invitationData.user })

            if (invitationData.userAddresses.length > 0) {
              this.setFormData('userAddresses', { controls: invitationData.userAddresses })
            }
          })
          // silent error
          .catch(() => {})
      }
    },
    updateInitialData () {
      Object.keys(this.initValues)
        .forEach(formDataPropertyName => {
          const FORMDATA_PROPERTY_DEFAULT = copy(COMPONENT_REGISTRATION_FORMDATA_DEFAULT[formDataPropertyName])

          if (Array.isArray(FORMDATA_PROPERTY_DEFAULT)) {
            const initValues = this.initValues[formDataPropertyName] || []
            this.formData[formDataPropertyName] = this.formData[formDataPropertyName] || []

            initValues
              .forEach((initValue, vIndex) => {
                this.formData[formDataPropertyName][vIndex] = Object.assign(this.formData[formDataPropertyName][vIndex] || {}, initValue || {})
              })
          } else {
            this.formData[formDataPropertyName] = Object.assign(this.formData[formDataPropertyName] || {}, this.initValues[formDataPropertyName] || {})
          }

          this.setCustomData(formDataPropertyName, { controls: this.formData[formDataPropertyName] })
        })
    },
    setFormData (formDataPropertyName = '', formData = {}) {
      const FORMDATA_PROPERTY_DEFAULT = copy(COMPONENT_REGISTRATION_FORMDATA_DEFAULT[formDataPropertyName])

      if (Array.isArray(FORMDATA_PROPERTY_DEFAULT)) {
        this.$set(this.formData, formDataPropertyName, [formData.controls])
      } else {
        this.$set(this.formData, formDataPropertyName, formData.controls)
      }

      this.setCustomData(formDataPropertyName, formData)

      if (this.mode === 'checkout') this.submitCheckoutForm(formDataPropertyName, formData)
    },
    setIsValid (formDataPropertyName = '', isValid = false) {
      this.formValidation[formDataPropertyName] = isValid
    },
    setCustomData (formDataPropertyName, formData = {}) {
      if (!this.omitLogin && formDataPropertyName === 'user') {
        if (formData.controls.email) {
          this.$set(this.formData.login, 'email', formData.controls.email)
        }
      }
    },
    validateForm () {
      Object.values(this.formIds)
        .forEach(id => {
          this.$root.$emit(`${id}:validate`)
        })
    },
    submitForm () {
      this.validateForm()

      if (this.formValid) {
        this.submitted = true

        this.$http({
          method: 'post',
          url: '/customer/api/Registration',
          data: this.formData
        })
          .then(response => {
            this.completionData = this.composeCompletionData(response.data)
          })
          .catch(error => {
            this.completionData = this.composeCompletionData(error.response.data)
          })
      }
    },
    submitCheckoutForm (formDataPropertyName = '', formData = {}) {
      const KEY = COMPONENT_REGISTRATION_DEFAULT_TO_CHECKOUT_MAP[formDataPropertyName]
      const CONTROLS = Object.keys(formData.controls)
        .reduce((data, cKey) => Object.assign(data, { [cKey]: formData.controls[cKey] }), {})

      if (KEY) {
        emitControlChange.bind(this)(KEY, CONTROLS)
      } else {
        Object.keys(CONTROLS)
          .forEach(cKey => emitControlChange.bind(this)(cKey, CONTROLS[cKey]))
      }

      function emitControlChange (key = null, value) {
        if (key) {
          const context = !this.emitChangesDirectly ? this.optionformContext : this
          context.$emit('control:change', { key, value })
        }
      }
    },
    composeCompletionData (response) {
      return {
        language: this.$store.getters['gui/language:get'],
        result: response.result || {},
        success: response.success,
        backToForm: function () {
          this.submitted = false
          this.completionData = null
        }.bind(this)
      }
    },
    modifyControlDefinitionVisibility (entityKey, definition) {
      const modifiedDefinition = (COMPONENT_REGISTRATION_HIDE_CONTROL_BY_NAME[entityKey] || []).reduce((def, pName) => {
        const propertyDefinition = def.properties.find(p => p.name === pName) || null
        if (propertyDefinition === null) {
          return def
        }

        const propertyConfiguration = propertyDefinition.settings
        this.$set(propertyConfiguration, 'hidden', true)

        return def
      }, definition)

      return modifiedDefinition
    }
  },
  beforeCreate () {
    this.stepContext = getNamedParentContext(CheckoutStep.name, this)
    this.optionformContext = getNamedParentContext(CheckoutOptionForm.name, this)
  },
  created () {
    if (this.mode === 'checkout') {
      this.stepContext.$on('checkoutstep:validate', this.validateForm)
    } else {
      this.getInitialData()
    }
  },
  watch: {
    '$props.initValues': {
      immediate: true,
      deep: true,
      handler () {
        this.updateInitialData()
      }
    }
  }
}
</script>

<style lang="scss">
$registration-form-margin-y: $spacer !default;
$registration-form-padding-y: $spacer !default;
$registration-form-border-y: map-get($borders, 'base') !default;

$registration-action-gap: $spacer !default;

.registration {
  .registration-form {
    margin-top: $registration-form-margin-y;
    margin-bottom: $registration-form-margin-y;
    padding-top: $registration-form-padding-y;
    padding-bottom: $registration-form-padding-y;
    border-top: $registration-form-border-y;
    border-bottom: $registration-form-border-y;

    &.no-border-bottom {
      border-bottom: none;
    }
  }

  .registration-termsandpolicies {}

  .registration-action {
    margin-top: $registration-action-gap;
  }
}
</style>
