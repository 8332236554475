<template>
  <div class="account-order">
    <loader v-if="is.loading"/>

    <template v-else-if="orderFound">
      <c-basket
        class="mt-0"
        moduleName="order"
        :preloadedBasket="executedBasket"
        :issuedGiftCards="order.issuedGiftCards"
        :isQuote="configuration.orderType === 'Quote'"
        :quoteValidUntil="configuration.quoteValidUntil"
        :show-item-discount="isReseller"
        :translationsPath="tPath"
      >
        <template #basketitemservicebuttons="slotProps">
          <slot name="basketitemservicebuttons" v-bind="slotProps"/>
        </template>
      </c-basket>

      <order-configuration
        :summary="summary"
        :configuration="configuration"
        :usedCoupons="usedCoupons"
        :isReseller="isReseller"
        :translations="Object.assign({ summary: $t(`${tPath}.summary`) }, $t(`${tPath}.configuration`))"
      />
    </template>
    <template v-else>
      <b-alert variant="warning" :show="true">
        <h2>{{ $t(`${tPath}.ordernotfound.title`) }}</h2>
        <p>{{ $t(`${tPath}.ordernotfound.description`) }}</p>
      </b-alert>
    </template>

    <slot name="afterorder" v-bind="{ order }" />
  </div>
</template>

<script>
import { COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP, COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP } from '@/constants'

import { getUrlSlugs } from '@/assets/js/helper/url'
import Loader from '@/components/private/Loader'
import OrderConfiguration from '@/components/private/OrderConfiguration'

const URL_SLUGS = getUrlSlugs()

export default {
  name: 'AccountOrder',
  components: {
    Loader,
    OrderConfiguration
  },
  props: {
    isReseller: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      orderId: URL_SLUGS[URL_SLUGS.length - 1]
    }
  },
  computed: {
    is () {
      return this.$store.getters['orderaccessor/is']
    },
    order () {
      return this.$store.getters['orderaccessor/getOrder'](this.orderId)
    },
    orderFound () {
      return this.order !== undefined && this.order !== null
    },
    executedBasket () {
      return this.order?.executedBasket ?? {}
    },
    configuration () {
      return this.order?.configuration ?? {}
    },
    usedCoupons () {
      return this.order?.usedCoupons ?? []
    },
    summary () {
      const priceDetails = this.executedBasket.priceDetail || {}

      return []
        .concat(priceDetails.priceLines ?? [], {
          type: COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.total.type,
          label: priceDetails.totalPriceLabel ?? {},
          price: priceDetails.totalPrice
        })
        .map(p => Object.assign({}, p, {
          component: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].component,
          attrs: COMPONENT_CHECKOUTSUMMARY_COMPONENT_TYPE_MAP[p.type].attrs,
          label: p.label[this.$store.getters['gui/language:get']]
        }))
        .reduce((summary, p) => summary.concat(p.type === COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.subtotal.type ? [COMPONENT_CHECKOUTSUMMARY_TYPE_COMPONENT_MAP.separator, p] : p), [])
    }
  },
  methods: {
    getOrder () {
      this.$store.dispatch('orderaccessor/getOrder', this.orderId)
    }
  },
  created () {
    this.getOrder()
  }
}
</script>

<style lang="scss">
.account-order {}
</style>
